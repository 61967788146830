import PropTypes from 'prop-types';
import * as React from 'react';

import Layout from '../components/Layout';
import Slideshow from '../components/Slideshow';

const IndexPage = ({ location }) => {
  return (
    <>
      <Layout>
        <Slideshow location={location}></Slideshow>
      </Layout>
    </>
  );
};

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default IndexPage;
